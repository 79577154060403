/* スライドショー
-------------------------------------------- */
.topMv {
  position: relative;
  width: 100%;
  height: 45vh;

  @include media(md){
    height: 95vh;
  }
}

.swiperMv {
  width: 100%;
  height: 100%;
}

.slide-img {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &.--slide01 {
    background-image: url(../images/pages/top/mv/mv_01_sp.png);
    background-image: image-set(
      url(../images/pages/top/mv/mv_01_sp.png) 1x,
      url(../images/pages/top/mv/mv_01_sp@2x.png) 2x
    );

    @include media(md) {
      background-image: url(../images/pages/top/mv/mv_01_pc.png);
      background-image: image-set(
        url(../images/pages/top/mv/mv_01_pc.png) 1x,
        url(../images/pages/top/mv/mv_01_pc@2x.png) 2x
      );
    }
  }
}

.topMv__text{
  position: absolute;
  bottom: 15px;
  left: 30px;
  width: 297px;
  max-width: 80%;
  z-index: 10;

  @include media(sm){
    bottom: 40px;
    left: 40px;
    width: res(563px);
  }

  @include media(md){
    bottom: res(100px);
    left: res(110px);
    width: res(563px);
  }

  @include media(lg){
    bottom: 100px;
    left: 110px;
    width: 563px;
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
}
