/* footer
------------------------------------------------ */
.footer{
  padding: 50px 0 30px;
  background-color: $color-bk;

  @include media(md) {
    padding: res(70px) 0 res(30px);
  }

  @include media(lg) {
    padding: 70px 0 30px;
  }

  &.--recruit{
    padding-bottom: 100px;

    @include media(sm) {
      padding-bottom: 30px;
    }

    @include media(md) {
      padding-bottom: res(30px);
    }

    @include media(lg) {
      padding-bottom: 30px;
    }
  }
}

.footer__logo{
  width: 240px;

  @include media(md) {
    width: res(305px);
  }

  @include media(lg) {
    width: 305px;
  }

  & > a{
    display: block;
    width: 100%;
  }
}

.footer__info{
  margin-top: 25px;
  font-size: 1.4rem;
  line-height: line(28,14);
  color: $color-wh;

  @include media(md) {
    margin-top: res(25px);
    font-size: res(1.5rem);
  }

  @include media(lg) {
    margin-top: 25px;
    font-size: 1.5rem;
    line-height: line(28,15);
  }
}

.footer__copy{
  margin-top: 70px;
  font-size: 1.2rem;
  color: $color-wh;

  @include media(md) {
    text-align: right;
    margin-top: res(60px);
    font-size: res(1.3rem);
  }

  @include media(lg) {
    margin-top: 60px;
    font-size: 1.3rem;
  }
}
