/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 90px;
  padding: 20px 17px;
  background: linear-gradient($color-bk, rgba(60,60,60,0) 80%);


  @include media(md) {
    height: res(120px);
    padding: res(40px) 20px;
  }

  @include media(lg) {
    height: 120px;
    padding: 40px 35px;
  }
}

.header__inner {
  display: flex;
}

.logoWrap > a{
  @include media(md) {
    display: flex;
    align-items: flex-end;
  }
}

.headerLogo {
  width: 230px;

  @include media(md) {
    width: 220;
  }

  @include media(lg) {
    width: 305px;
  }

  & .cls-1{
    fill: $color-wh;
    transition: 0.4s;
  }

  & .cls-2{
    fill: $color-wh;
    transition: 0.4s;
  }
}

.headerName{
  display: none;

  @include media(md) {
    display: block;
    margin-left: res(15px);
    margin-bottom: res(5px);
    width: 130px;
  }

  @include media(lg) {
    margin-left: 15px;
    margin-bottom: 5px;
    width: 180px;
  }

  & .cls-1{
    fill: $color-wh;
    transition: 0.4s;
  }
}

.headerNav {
  margin-left: auto;

  .gnav-sp {
    position: relative;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 26px;
    height: 22px;

    @include media(md) {
      display: none;
    }
  }

  // gnav
  .gnav {
    display: none;

    @include media(md) {
      display: flex;
    }
  }

  .gnav__list {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gnav__item {
    position: relative;
    margin-right: res(30px);

    @include media(lg) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;

      @include media(md) {
        margin-right: res(30px);
      }

      @include media(lg) {
        margin-right: 30px;
      }
    }

    & > a {
      position: relative;
      font-size: res(1.6rem);
      font-weight: 700;
      color: $color-wh;

      @include media(md) {
        font-size: res(1.6rem);
      }

      @include media(lg) {
        font-size: 1.6rem;
      }
    }
  }
}

.gnav__contact{
  & > a{
    padding: res(6px) res(20px) res(8px);
    font-size: res(1.6rem);
    font-weight: 700;
    color: $color-wh;
    border: 1px solid $color-wh;
    border-radius: res(8px);

    @include media(lg) {
      padding: 6px 20px 8px;
      font-size: 1.6rem;
      border-radius: 8px;
    }
  }
}

// header lower
// ---------------------------------------------------

.header.--lower{
  background: linear-gradient($color-wh,rgba(255,255,255,0) 80%);

  & .headerLogo {
    & .cls-1{
      fill: $color-bk;
      transition: 0.4s;
    }

    & .cls-2{
      fill: #0000cd;
      transition: 0.4s;
    }
  }

  .headerName{
    & .cls-1{
      fill: $color-bk;
    }
  }

  .drawerBtn {
    & > span {
      background-color: $color-bk;
    }
  }

  .gnav__item > a {
    color: $color-bk;
  }

  .gnav__contact > a{
      color: $color-bk;
      border: 1px solid $color-bk;
  }
}
