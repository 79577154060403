// drawerBtn
// ---------------------------------------------------
.drawerBtn {
  position: relative;
  width: 100%;
  z-index: 101;
  cursor: pointer;

  & > span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: $color-wh;
    transform-origin: 50%;
    transition: 0.4s;

    &:nth-of-type(1) {
      transform: rotate(0) translateY(-10px);
    }

    &:nth-of-type(3) {
      transform: rotate(0) translateY(10px);
    }
  }

  &.active {
    & > span {
      &:nth-of-type(1) {
        transform: translateY(0) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}

// overlay
// ---------------------------------------------------
.drawerOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 98;

  @include media(md) {
    display: none;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }
}

//　drawerModal
// ---------------------------------------------------
.drawerModal {
  position: fixed;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: $color-bk;
  transition: all 0.5s;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  // &.--fadeIn {
  //   inset: 0;
  //   visibility: hidden;
  //   opacity: 0;

  //   &.open {
  //     visibility: visible;
  //     opacity: 1;
  //   }
  // }

  &.--slideRight {
    top: 0;
    right: -230px;
    max-width: 230px;

    @include media(sm) {
      right: -300px;
      max-width: 300px;
    }

    &.open {
      right: 0;
    }
  }
}

.drawerModal__inner {
  position: relative;
  overflow: hidden;
  height: auto;
  padding: 70px 40px;
}

.drawerModal__item {
  position: relative;

  & > a {
    display: block;
    padding: 20px 0;
    font-size: 1.8rem;
    font-weight: 700;
    color: $color-wh;
  }
}

.drawerModal__contact{
  margin-top: 20px;

  & > a{
    display: block;
    width: 100%;
    padding: 8px 0 12px;
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    line-height: line(24,18);
    color: $color-wh;
    border: 1px solid $color-wh;
    border-radius: 8px;
  }
}

// drawer lower
// ---------------------------------------------------

.drawerOverlay.--lower{
  background-color: rgba(60, 60, 60, 0.7);
}

.drawerModal.--lower{
  background-color: $color-wh;

  & .drawerModal__item {
    & > a {
      color: $color-bk;
    }
  }

  & .drawerModal__contact{
    margin-top: 20px;

    & > a{
      color: $color-bk;
      border: 1px solid $color-bk;
    }
  }
}
