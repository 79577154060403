/* recruit
------------------------------------------------ */
.recruit{
  position: relative;

  & section{
    background-color: $bg-gray;

    &.entry{
      background-color: $color-wh;
    }
  }
}

/* rctAbout
------------------------------------------------- */
.rctAbout{
  padding-top: 95px;

  @include media(md) {
    padding-top: res(110px);
  }

  @include media(lg) {
    padding-top: 110px;
  }
}

.rctAbout__text{
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  line-height: line(31,15);

  @include media(md) {
    font-size: res(1.8rem);
    line-height: line(38,18);
  }

  @include media(lg) {
    font-size: 1.8rem;
  }
}

/* rctMessage
------------------------------------------------- */
.rctMessage{
  padding-top: 95px;
  overflow: hidden;

  @include media(md) {
    padding-top: res(100px);
  }

  @include media(lg) {
    padding-top: 100px;
  }

}

.rctMessage__wrap{
  position: relative;
  z-index: 10;
  margin-top: 40px;

  @include media(md) {
    display: flex;
    margin-top: res(70px);
  }

  @include media(lg) {
    margin-top: 70px;
  }
}

.rctMessage__img{
  margin-left: -20px;
  width: 60vw;
  max-width: 360px;
  flex-shrink: 0;

  @include media(sm) {
    margin-left: 0;
  }

  @include media(md) {
    max-width: 100%;
    width: res(440px);
  }

  @include media(lg) {
    width: 440px;
  }

  & > img{
    width: 100%;
  }
}

.rctMessage__txtBox{
  position: relative;
  margin-left: 28px;
  padding: 30px 0 40px 35px;

  @include media(md) {
    margin-top: res(80px);
    padding: res(75px) 0 res(75px) res(70px);
  }

  @include media(lg) {
    margin-top: 80px;
    padding: 75px 0 75px 70px;
  }

  &::after{
    content: '';
    position: absolute;
    top: -145px;
    left: 0;
    width: calc(100% + 20px);
    height: calc(100% + 145px);
    background-color: $color-wh;
    z-index: -1;

    @include media(sm) {
      width: calc(100% + 60px);
      height: calc(100% + 145px);
    }

    @include media(md) {
      top: 0;
      left: res(-90px);
      width: 80vw;
      height: 100%;
    }

    @include media(lg) {
      left: -90px;
    }
  }
}

.rctMessage__title{
  font-size: 1.8rem;
  line-height: line(30,18);

  @include media(md) {
    font-size: res(2.6rem);
    line-height: line(48,26);
  }

  @include media(lg) {
    font-size: 2.6rem;
  }
}

.rctMessage__text{
  margin-top: 15px;
  font-size: 1.3rem;
  line-height: line(25,13);

  @include media(md) {
    margin-top: res(35px);
    font-size: res(1.8rem);
    line-height: line(36,18);
  }

  @include media(lg) {
    margin-top: 35px;
    font-size: 1.8rem;
  }
}

.rctMessage__co{
  margin-top: 25px;
  font-size: 1.2rem;

  @include media(md) {
    margin-top: res(30px);
    font-size: res(1.4rem);
  }

  @include media(lg) {
    margin-top: 30px;
    font-size: 1.4rem;
  }
}

.rctMessage__name{
  margin-top: 10px;
  font-size: 1.5rem;

  @include media(md) {
    margin-top: res(15px);
    font-size: res(2rem);
  }

  @include media(lg) {
    margin-top: 15px;
    font-size: 2rem;
  }
}

/* outline
------------------------------------------------- */
.outline{
  padding-top: 100px;

  @include media(md) {
    padding-top: res(130px);
  }

  @include media(lg) {
    padding-top: 130px;
  }
}

.outline__title{
  margin-top: 40px;
  font-size: 2.2rem;

  @include media(md) {
    margin-top: res(60px);
    font-size: res(2.8rem);
  }

  @include media(lg) {
    margin-top: 60px;
    font-size: 2.8rem;
  }

  & > .notRecruit{
    margin-left: 7px;
    font-size: 1.3rem;
    color: $color-wh;
    background-color: $color-red;
    padding: 2px 0px 4px 6px;
    vertical-align: 25%;

    @include media(md) {
      margin-left: res(20px);
      font-size: res(1.7rem);
      padding: 1px 5px 3px 12px;
    }

    @include media(lg) {
      margin-left: 20px;
      font-size: 1.7rem;
    }
  }
}

.olList{
  margin-top: 20px;

  @include media(md) {
    margin-top: res(20px);
  }

  @include media(lg) {
    margin-top: 20px;
  }
}

.olList__item{
  display: flex;
  padding: 10px 15px 12px;
  background-color: $color-wh;
  border-top: 1px solid $line-gray;

  @include media(md) {
    padding: res(20px) res(40px) res(22px);
  }

  @include media(lg) {
    padding: 20px 40px 22px;
  }

  &:last-child{
    border-bottom: 1px solid $line-gray;
  }
}

.olList__title{
  width: 55px;
  font-size: 1.3rem;
  line-height: line(23,13);

  @include media(md) {
    width: res(110px);
    font-size: res(1.8rem);
    line-height: line(32,18);
  }

  @include media(lg) {
    width: 110px;
    font-size: 1.8rem;
  }
}

.olList__text{
  margin-left: 15px;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: line(23,13);

  @include media(md) {
    margin-left: res(80px);
    font-size: res(1.8rem);
    line-height: line(32,18);
  }

  @include media(lg) {
    margin-left: 80px;
    font-size: 1.8rem;
  }
}

/* jobDetails
------------------------------------------------- */
.jobDetails{
  padding-top: 100px;

  @include media(md) {
    padding-top: res(130px);
  }

  @include media(lg) {
    padding-top: 130px;
  }
}

.jobDetails__list{
  margin: 0 auto;
  margin-top: 40px;
  max-width: 550px;

  @include media(sm) {
    display: flex;
    flex-wrap: wrap;
    margin-top: res(20px);
    margin-left: res(-44px);
    max-width: none;
  }

  @include media(lg) {
    margin-top: 20px;
    margin-left: -44px;
  }
}

.jobDetails__item{
  margin-top: 35px;

  @include media(sm) {
    margin-top: res(50px);
    margin-left: res(44px);
    width: calc(50% - #{res(44px)});
  }

  @include media(lg) {
    margin-top: 50px;
    margin-left: 44px;
    width: calc(50% - 44px);
  }
}

.jobDetails__title{
  margin-top: 20px;
  font-size: 2.2rem;

  @include media(sm) {
    margin-top: res(20px);
    font-size: res(2.6rem);
  }

  @include media(lg) {
    margin-top: 20px;
    font-size: 2.6rem;
  }
}

.jobDetails__text{
  margin-top: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: line(26,15);

  @include media(sm) {
    margin-top: res(15px);
    font-size: res(1.8rem);
    line-height: line(32,18);
  }

  @include media(lg) {
    margin-top: 15px;
    font-size: 1.8rem;
  }
}

/* welfare
------------------------------------------------- */
.welfare{
  padding-top: 95px;

  @include media(md) {
    padding-top: res(120px);
  }

  @include media(lg) {
    padding-top: 120px;
  }
}

.welfare__text{
  margin-top: 20px;
  font-size: 1.5rem;
  line-height: line(28,16);

  @include media(md) {
    margin-top: res(40px);
    font-size: res(1.8rem);
    line-height: 1;
  }

  @include media(lg) {
    margin-top: 40px;
    font-size: 1.8rem;
  }
}

.welfare__list{
  margin-top: 5px;
  margin-left: -18px;
  display: flex;
  flex-wrap: wrap;

  @include media(md) {
    margin-top: 0;
    margin-left: res(-44px);
  }

  @include media(lg) {
    margin-left: -44px;
  }
}

.welfare__item{
  margin-top: 18px;
  margin-left: 18px;
  width: calc(50% - 18px);
  padding: 15px 10px 10px;
  background-color: $color-wh;
  border-radius: 8px;

  @include media(md) {
    margin-top: res(40px);
    margin-left: res(44px);
    width: calc(25% - #{res(44px)});
    padding: res(20px) res(15px) res(15px);
  }

  @include media(lg) {
    margin-top: 40px;
    margin-left: 44px;
    width: calc(25% - 44px);
    padding: 20px 15px 15px;
  }

  &:nth-child(4n - 1),
  &:nth-child(4n - 2) {
    background-color: $bg-indigo;

    @include media(md) {
      background-color: $color-wh;
    }
  }

  &:nth-child(8n - 1),
  &:nth-child(8n - 3),
  &:nth-child(8n - 4),
  &:nth-child(8n - 6) {
    @include media(md) {
      background-color: $bg-indigo
    }
  }
}

.welfare__number{
  position: relative;
  padding-bottom: 15px;
  font-family: $font-en;
  font-size: 3rem;
  text-align: center;
  color: $color-blue;

  @include media(md) {
    padding-bottom: res(20px);
    font-size: res(4rem);
  }

  @include media(lg) {
    padding-bottom: 20px;
    font-size: 4rem;
  }

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 3px;
    background-color: $color-bk;

    @include media(md) {
      width: res(16px);
      height: res(4px);
    }

    @include media(lg) {
      width: 16px;
      height: 4px;
    }
  }
}

.welfare__title{
  margin-top: 10px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  line-height: line(20,14);
  text-align: center;

  @include media(md) {
    margin-top: res(10px);
    height: res(85px);
    font-size: res(1.9rem);
    line-height: line(27,19);
  }

  @include media(lg) {
    margin-top: 10px;
    height: 85px;
    font-size: 1.9rem;
  }

  & .small{
    font-size: 1.2rem;
    line-height: line(19,12);

    @include media(md) {
      font-size: res(1.6rem);
      line-height: line(21,16);
    }

    @include media(lg) {
      font-size: 1.6rem;
    }
  }
}

.welfare__note{
  margin-top: 15px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: line(24,14);

  @include media(md) {
    margin-top: res(45px);
    font-size: res(1.6rem);
  }

  @include media(lg) {
    margin-top: 45px;
    font-size: 1.6rem;
  }
}

/* interview
------------------------------------------------- */
.interview{
  padding-top: 95px;
  overflow: hidden;

  @include media(md) {
    padding-top: res(125px);
  }

  @include media(lg) {
    padding-top: 125px;
  }
}

.interview__wrap{
  position: relative;
  z-index: 10;
  display: flex;
  margin-top: 40px;
  padding: 0 5px;

  @include media(md) {
    padding: 0;
    margin-top: res(80px);
  }

  @include media(lg) {
    margin-top: 80px;
  }
}

.interview__content{
  position: relative;
  padding: 40px 16px 35px 0;

  @include media(sm) {
    margin-top: res(70px);
    padding: res(55px) res(50px) res(50px) 0;
  }

  @include media(lg) {
    margin-top: 70px;
    padding: 55px 50px 50px 0;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: -25px;
    width: calc(100% + 55px);
    height: 100%;
    background-color: $color-wh;
    z-index: -1;

    @include media(sm) {
      right: res(-94px);
      width: 90vw;
      height: 100%;
    }

    @include media(lg) {
      right: -94px;
    }
  }
}

.interview__item{
  margin-top: 20px;

  @include media(sm) {
    margin-top: res(35px);
  }

  @include media(lg) {
    margin-top: 35px;
  }

  &:first-child{
    margin-top: 0;
  }
}

.interview__title{
  font-size: 1.4rem;
  line-height: line(19,14);
  color: $color-blue;

  @include media(sm) {
    font-size: res(1.9rem);
  }

  @include media(lg) {
    font-size: 1.9rem;
  }
}

.interview__text{
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: line(20,12);

  @include media(sm) {
    margin-top: res(15px);
    font-size: res(1.6rem);
    line-height: line(26,16);
  }

  @include media(lg) {
    margin-top: 15px;
    font-size: 1.6rem;
  }
}

.intPperson{
  margin-top: 30px;

  @include media(sm) {
    margin-top: 0;
  }

  & > img{
    width: 25vw;
    height: 25vw;
    min-width: 88px;
    min-height: 88px;
    max-width: 130px;
    max-height: 130px;
    flex-shrink: 0;

    @include media(sm) {
      max-width: none;
      max-height: none;
      width: res(218px);
      height: res(218px);
    }

    @include media(lg) {
      width: 218px;
      height: 218px;
    }
  }
}

.intPperson__years{
  margin-top: 18px;
  font-size: 1.4rem;
  font-weight: 400;
  text-align: right;
  line-height: line(22,14);

  @include media(sm) {
    margin-top: res(20px);
    font-size: res(1.8rem);
  }

  @include media(lg) {
    margin-top: 20px;
    font-size: 1.8rem;
  }
}

.intPperson__name{
  margin-top: 5px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: right;

  @include media(sm) {
    margin-top: res(5px);
    font-size: res(2rem);
  }

  @include media(lg) {
    margin-top: 5px;
    font-size: 2rem;
  }

  .strong{
    font-size: 2.2rem;

    @include media(sm) {
      font-size: res(2.8rem);
    }

    @include media(lg) {
      font-size: 2.8rem;
    }
  }
}

/* 偶数番目 ------------------------ */
.interview__wrap:nth-child(2n+1){
  .interview__content{
    padding-right: 0;
    padding-left: 16px;

    @include media(md) {
      padding-left: res(50px);
    }

    @include media(lg) {
      padding-left: 50px;
    }

    &::after{
      left: -30px;

      @include media(md) {
        left: res(-94px);
      }

      @include media(lg) {
        left: -94px;
      }
    }
  }

  & .intPperson__years,
  & .intPperson__name{
    text-align: left;
  }
}

/* faq
------------------------------------------------- */
.faq{
  padding-top: 100px;
  padding-bottom: 55px;

  @include media(md) {
    padding-top: res(130px);
    padding-bottom: res(150px);
  }

  @include media(lg) {
    padding-top: 130px;
    padding-bottom: 150px;
  }
}

.faq__list{
  margin-top: 40px;

  @include media(md) {
    margin-top: res(70px);
  }

  @include media(lg) {
    margin-top: 70px;
  }
}

.faq__item{
  margin-top: 25px;

  @include media(md) {
    margin-top: res(40px);
  }

  @include media(lg) {
    margin-top: 40px;
  }
}

.faq__qBox{
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 18px;
  border-radius: 30px;
  background-color: $color-blue;

  @include media(md) {
    height: res(54px);
    padding: 0 res(25px);
  }

  @include media(lg) {
    height: 54px;
    padding: 0 25px;
  }
}

.faq__item:nth-child(2n){
  & .faq__qBox{
    background-color: $color-lightblue;
  }
}

.faq__aBox{
  display: flex;
  margin-top: 15px;
  padding-left: 18px;

  @include media(md) {
    margin-top: res(20px);
    padding-left: res(25px);
  }

  @include media(lg) {
    margin-top: 20px;
    padding-left: 25px;
  }
}

.faq__alphabet{
  font-family: $font-en;
  font-size: 2.1rem;
  color: $color-wh;

  @include media(md) {
    font-size: res(2.8rem);
  }

  @include media(lg) {
    font-size: 2.8rem;
  }

  &.--red{
    color: $color-red;
  }
}

.faq__text{
  margin-top: -3px;
  margin-left: 15px;
  font-size: 1.3rem;
  line-height: line(21,13);

  @include media(md) {
    margin-top: -1px;
    margin-left: res(25px);
    font-size: res(1.8rem);
    line-height: line(30,18);
  }

  @include media(lg) {
    margin-left: 25px;
    font-size: 1.8rem;
  }

  &.--wh{
    color: $color-wh;
  }
}

.faq__img{
  margin: 0 auto;
  margin-top: 55px;
  width: 268px;
  max-width: 100%;

  @include media(sm) {
    margin-top: res(115px);
    width: res(580px);
  }

  @include media(lg) {
    margin-top: 115px;
    width: 580px;
  }

  & > img{
    width: 100%;
  }
}

.faq__comment{
  margin-top: 15px;
  font-size: 1.8rem;
  text-align: center;
  line-height: line(30,18);

  @include media(md) {
    margin-top: res(35px);
    font-size: res(2.6rem);
    line-height: 1;
  }

  @include media(lg) {
    margin-top: 35px;
    font-size: 2.6rem;
  }
}

/* entry
------------------------------------------------- */
.entry{
  padding: 60px 0;

  @include media(md) {
    padding-top: res(115px);
    padding-bottom: res(125px);
  }

  @include media(lg) {
    padding-top: 115px;
    padding-bottom: 125px;
  }
}

.entry .topTitle.--contact{
  font-size: 3rem;
  line-height: line(38,30);

  @include media(md) {
    font-size: 4.6rem;
  }

  @include media(lg) {
    font-size: 4.6rem;
  }
}

.entry__text{
  margin-top: 30px;
  font-size: 1.2rem;
  text-align: center;
  line-height: line(25,12);

  @include media(md) {
    margin-top: res(45px);
    font-size: res(1.6rem);
    line-height: line(34,16);
  }

  @include media(lg) {
    margin-top: 45px;
    font-size: 1.6rem;
  }

  & .blue{
    color: $color-blue;
  }
}

.entry__button{
  margin-top: 30px;

  @include media(md) {
    margin-top: res(50px);
  }

  @include media(lg) {
    margin-top: 50px;
  }
}

/* backtoTop
------------------------------------------------- */
.backtoTop{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background-color: $bg-gray;

  @include media(md) {
    padding: res(110px) 0;
  }

  @include media(lg) {
    padding: 110px 0;
  }

  & > a{
    display: block;
    padding: 10px;

    @include media(md) {
      padding: res(20px);
    }

    @include media(lg) {
      padding: 20px;
    }
  }
}

.backtoTop__text{
  font-size: 1.8rem;
  text-align: center;

  @include media(md) {
    font-size: res(2.2rem);
  }

  @include media(lg) {
    font-size: 2.2rem;
  }
}

/* entryBtn
------------------------------------------------- */
.entryBtn{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 70px;

  @include media(sm) {
    bottom: auto;
    left: auto;
    top: 80px;
    right: -2px;
    width: res(132px);
    height: res(132px);
  }

  @include media(md) {
    top: res(135px);
  }

  @include media(lg) {
    top: 135px;
    width: 132px;
    height: 132px;
  }

  & > a{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-blue;
    border-radius: 8px 8px 0 0;

    @include media(sm) {
      flex-direction: column;
      border: 2px solid $color-wh;
      border-radius: 8px 0 0 8px;
    }
  }
}

.entryBtn__title{
  margin-top: 2px;
  font-family: $font-en;
  font-size: 3rem;
  color: $color-wh;

  @include media(sm) {
    margin-top: 0;
    font-size: res(3rem);
  }

  @include media(lg) {
    font-size: 3rem;
  }
}

.entryBtn__text{
  margin-left: 12px;
  font-size: 1.4rem;
  color: $color-wh;

  @include media(sm) {
    margin-left: 0;
    margin-top: res(5px);
    font-size: res(1.4rem);
    text-align: center;
    line-height: line(19,14);
  }

  @include media(lg) {
    margin-top: 5px;
    font-size: 1.4rem;
  }
}
