.contact {

  .red {
    color: $color-red;
  }

  .blue {
    color: $color-blue;
  }

  .custom-error-message {
    margin-top: 0.5em;
  }

  .contactMain {
    padding-bottom: 100px;

    @include media(md) {
      padding-bottom: 160px;
    }
  }

  // 送信ボタン
  .contact__thanksBtn,
  button[data-action="confirm"],
  button[data-action="back"],
  button[data-action="complete"] {
    position: relative;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    background: $color-blue;
    color: white;
    width: 260px;
    min-height: 74px;
    font-size: 20px;
    font-weight: 700;
    margin: 0 auto;
    border-radius: 10px;
    transition: opacity .6s ease-out;

    @include media(md) {
      width: 336px;
      min-height: 88px;
      font-size: 26px;
    }

    &:hover {
      opacity: .7;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: .6em;
      height: .6em;
      border-bottom: 2px solid white;
      border-left: 2px solid white;
    }

    &:disabled {
      filter: brightness(50%);
    }
  }

  button[data-action="confirm"],
  button[data-action="complete"] {
    &::before {
      right: 1em;
      transform: translateY(-50%) rotate(-135deg);
    }
  }

  .contact__thanksBtn,
  button[data-action="back"] {
    background: white;
    color: $color-blue;
    border: 2px solid $color-blue;

    &::before {
      border-color: $color-blue;
      left: 1em;
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .contact__thanksBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .thanks__text,
  .contact__text,
  .confirm__text {
    font-size: 14px;
    line-height: calc(29/16);
    font-weight: 400;
    margin-block: calc((1em - 1lh) / 2);
    padding-left: 0;
    margin-bottom: 42px;
    padding-bottom: 42px;
    border-bottom: 1px solid $line-gray;

    >span {
      display: inline-block;
    }

    @include media(md) {
      font-size: 16px;
      padding-left: 100px;
      margin-bottom: 90px;
      padding-bottom: 100px;
    }
  }

  .thanks__text {
    border: none;
    margin-bottom: 0;
  }

  .contact__policy {
    font-size: 14px;
    font-weight: 400;
    line-height: calc(29/16);
    margin-block: calc((1em - 1lh) / 2);
    margin: 40px 0;
    text-align: center;

    @include media(md) {
      font-size: 16px;
      margin: 56px 0;
    }

    >span {
      display: inline-block;
    }

    a {
      text-decoration: underline;
    }
  }


  // snow-monkey-formのCSSを上書き
  .snow-monkey-form {
    margin: 0 auto;
    width: 100%;
    max-width: 760px;

    input {
      width: 100%;
    }

    .smf-item {
      font-size: 14px;
      font-weight: 700;

      @include media(md) {
        font-size: 16px;
      }
    }

    .smf-item__col--label {
      padding-bottom: 1em;

      @include media(md) {
        padding-bottom: 0;
      }
    }

    .smf-item__label {
      height: 100%;

      .smf-item__label__text {
        position: relative;
        top: 0.5em;
        transform: translateY(-50%);
        margin-block: calc((1em - 1lh) / 2);
      }
    }

    .requier {
      .smf-item__label__text {
        position: relative;

        &::after {
          position: absolute;
          content: '＊';
          color: #fff;
          width: 1em;
          height: 1em;
          top: 50%;
          transform: translate(100%, -50%);
          right: 0;
          display: grid;
          place-items: center;
          color: $color-red;
          font-size: 1em;
        }
      }
    }

    .smf-button-control {
      &+.smf-button-control {
        margin: 0;
      }
    }

    .confirm__text {
      display: none;
    }

    .smf-form--simple-table .smf-item {
      padding: 12px 0;

      @include media(md) {
        padding: 15px 0;
      }
    }


    // 確認画面
    &[data-screen="confirm"] {

      .contact__text,
      .contact__policy {
        display: none;
      }

      .confirm__text {
        display: block;
      }

      .smf-action {
        margin-top: 64px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px 40px;

        @include media(md) {
          margin-top: 110px;
        }
      }

      .smf-placeholder {
        font-weight: 400;
      }

      .smf-form--simple-table .smf-item {
        padding: 1.5em 0;
      }

      .smf-placeholder {
        font-size: 16px;
        line-height: calc(29/16);

        @include media(md) {
          font-size: 16px;
        }
      }
    }
  }
}
