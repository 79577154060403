/* privacy
------------------------------------------------ */
.ppContent {
  padding-bottom: 100px;

  @include media(md) {
    padding-bottom: res(160px);
  }

  @include media(lg) {
    padding-bottom: 160px;
  }
}

.ppContent__text{
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: line(22,12);

  @include media(md) {
    margin-top: res(10px);
    font-size: res(1.6rem);
    line-height: line(29,16);
  }

  @include media(lg) {
    margin-top: 10px;
    font-size: 1.6rem;
  }

  &:first-child{
    margin-top: 0;
    padding-bottom: 20px;

    @include media(md) {
      padding-bottom: res(30px);
    }

    @include media(lg) {
      padding-bottom: 30px;
    }
  }

  & .nallow{
    @include media(md) {
      letter-spacing: track(-30);
    }
  }

  & .link{
    font-weight: 700;
    color: $color-blue;
    text-decoration: underline;
  }
}

.ppContent__title{
  margin-top: 25px;
  font-size: 1.5rem;

  @include media(md) {
    margin-top: res(35px);
    font-size: res(2rem);
  }

  @include media(lg) {
    margin-top: 35px;
    font-size: 2rem;
  }

  &.--mTop{
    margin-top: 50px;

    @include media(md) {
      margin-top: res(65px);
    }

    @include media(lg) {
      margin-top: 65px;
    }
  }
}
