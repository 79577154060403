//font-family
$font: "Noto Sans JP", sans-serif;
$font-en: "Roboto", sans-serif;
//font-size
$font-size: 10px;

// color
$color-bk: #3c3c3c;
$color-wh: #fff;
$color-red: #e64600;
$color-blue: #3282e6;
$color-lightblue: #6fa7ed;

$bg-blue: #e4f8ff;
$bg-gray: #f6f6f6;
$bg-indigo: #e4edf1;

$line-gray: #cecece;
