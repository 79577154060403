// linkBtn
// ---------------------------------------------------

.linkBtn{
  margin: 0 auto;
  width: 300px;
  max-width: 100%;

  @include media(md) {
    width: res(700px);
  }

  @include media(lg) {
    width: 700px;
  }

  &>a{
    position: relative;
    display: block;
    width: 100%;
    padding: 25px 0 27px;
    font-size: 2rem;
    text-align: center;
    font-weight: 700;
    color: $color-wh;
    background-color: $color-blue;
    border-radius: 8px;

    @include media(md) {
      padding: res(40px) 0 res(45px);
      font-size: res(3.6rem);
    }

    @include media(lg) {
      padding: 40px 0 45px;
      font-size: 3.6rem;
    }

    &::after{
      content: '';
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      width: 9.5px;
      height: 17px;
      background-image: url(../images/common/icon/button_arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;

      @include media(md) {
        right: res(30px);
        width: res(14px);
        height: res(25px);
      }

      @include media(lg) {
        right: 30px;
        width: 14px;
        height: 25px;
      }
    }
  }
}
