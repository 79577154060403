// .lower {
//   position: relative;
//   padding-top: 90px;

//   @include media(md) {
//     padding-top: res(120px);
//   }

//   @include media(lg) {
//     padding-top: 120px;
//   }
// }

// lowerMv
// ---------------------------------------------------

.lowerMv {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(../images/pages/lower/lowerMv_recruit_sp.png);

  @include media(sm) {
    background-image: url(../images/pages/lower/lowerMv_recruit_pc.png);
  }
}

.lowerMv__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 100px 0 60px;

  @include media(md) {
    padding: res(210px) 0 res(130px);
  }

  @include media(lg) {
    padding: 210px 0 130px;
  }
}

.lowerMv__title {
  display: flex;
  flex-direction: column;
  align-items: center;

  .en{
    height: 24px;

    &>img{
      height: 100%;
      width: auto;
    }

    @include media(md) {
      height: res(48px);
    }

    @include media(lg) {
      height: 48px;
    }
  }

  .jp {
    position: relative;
    margin-top: 20px;
    padding:  6px 13px 8px;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-wh;
    border-top: 2px solid $color-wh;
    border-bottom: 2px solid $color-wh;

    @include media(md) {
      margin-top: res(30px);
      padding:  res(10px) res(30px) res(12px);
      font-size: res(2.7rem);
    }

    @include media(lg) {
      margin-top: 30px;
      padding:  10px 30px 12px;
      font-size: 2.7rem;
      border-top: 3px solid $color-wh;
      border-bottom: 3px solid $color-wh;
    }

    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: -5px;
      width: 2px;
      transform: translateY(-50%);
      height: calc(100% - 8px);
      background-color: $color-wh;

      @include media(md) {}

      @include media(lg) {
        left: -9px;
        width: 3px;
        height: calc(100% - 10px);
      }
    }

    &::after{
      content: '';
      position: absolute;
      top: 50%;
      right: -5px;
      width: 2px;
      transform: translateY(-50%);
      height: calc(100% - 8px);
      background-color: $color-wh;

      @include media(md) {}

      @include media(lg) {
        right: -9px;
        width: 3px;
        height: calc(100% - 10px);
      }
    }
  }
}

// lowerHead
// ---------------------------------------------------

.lowerHead {
  padding: 140px 0 50px;

  @include media(md) {
    padding: res(180px) 0 res(90px);
  }

  @include media(lg) {
    padding: 180px 0 90px;
  }
}

.lowerHead__title {

  .en,
  .jp {
    color: $color-blue;
  }

  .jp {
    font-size: 3rem;
    font-weight: 700;

    @include media(sm) {
      font-size: res(4.4rem);
    }

    @include media(lg) {
      font-size: 4.4rem;
    }
  }

  .en {
    margin-top: 15px;
    font-size: 1.2rem;
    font-weight: 700;

    @include media(sm) {
      margin-top: res(20px);
      font-size: res(1.4rem);
    }

    @include media(lg) {
      margin-top: 20px;
      font-size: 1.4rem;
    }
  }
}

// lowerTitle
// ---------------------------------------------------
.lowerTitle{
  position: relative;
  padding-left: 24px;
  font-size: 2.7rem;
  color: $color-blue;

  @include media(sm) {
    padding-left: res(32px);
    font-size: res(3.6rem);
  }

  @include media(lg) {
    padding-left: 32px;
    font-size: 3.6rem;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 28px;
    background-color: $color-bk;

    @include media(sm) {
      width: res(6px);
      height: res(36px);
    }

    @include media(lg) {
      width: 6px;
      height: 36px;
    }
  }
}
