/* メインの設定(全体)
------------------------------- */

// common
.anker {
  display: block;
  margin-top: -100px;
  padding-top: 100px;

  @include media(sm) {
    margin-top: -100px;
    padding-top: 100px;
  }

  @include media(md) {
    margin-top: res(-150px);
    padding-top: res(150px);
  }

  @include media(lg) {
    margin-top: -150px;
    padding-top: 150px;
  }
}

// inner
.inner {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;

  @include media(sm) {
    padding: 0 60px;
  }

  @include media(md) {
    padding: 0;
    max-width: res(980px);
  }

  @include media(lg) {
    max-width: 980px;
  }
}

// main
.main {
  position: relative;
  display: block;
}

//asp
.--asp {
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: block;
  }

  img,
  iframe,
  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// br関連
.brSp {
  display: block;

  @include media(sm) {
    display: none;
  }
}

.brTb {
  display: none;

  @include media(sm) {
    display: block;
  }

  @include media(md) {
    display: none;
  }
}

.brPc {
  display: none;

  @include media(md) {
    display: block;
  }
}

.brSp_Tb {
  display: block;

  @include media(md) {
    display: none;
  }
}

.brTb_Pc {
  display: none;

  @include media(sm) {
    display: block;
  }
}
