/* TOP
------------------------------------------------- */

/* topTitle
------------------------------------------------- */
.topTitle{
  font-size: 3.8rem;

  @include media(md) {
    font-size: res(5.2rem);
  }

  @include media(lg) {
    font-size: 5.2rem;
  }

  & .blue{
    color: $color-blue;
  }

  &.--center{
    text-align: center;
  }

  &.--company{
    font-size: 2.6rem;
    color: $color-wh;

    @include media(md) {
      font-size: res(4.2rem);
    }

    @include media(lg) {
      font-size: 4.2rem;
    }
  }

  &.--contact{
    position: relative;
    font-size: 2.6rem;
    padding-bottom: 35px;

    @include media(md) {
      font-size: res(4.6rem);
      padding-bottom: res(55px);
    }

    @include media(lg) {
      font-size: 4.6rem;
      padding-bottom: 55px;
    }

    &::before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-100%);
      width: 12px;
      height: 3px;
      background-color: $color-bk;

      @include media(md) {
        width: res(16px);
        height: res(4px);
      }

      @include media(lg) {
        width: 16px;
        height: 4px;
      }
    }

    &::after{
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 12px;
      height: 3px;
      background-color: $color-blue;

      @include media(md) {
        width: res(16px);
        height: res(4px);
      }

      @include media(lg) {
        width: 16px;
        height: 4px;
      }
    }
  }
}

/* topAbout
------------------------------------------------- */
.topAbout{
  padding-top: 80px;

  @include media(md) {
    padding-top: res(150px);
  }

  @include media(lg) {
    padding-top: 150px;
  }
}

.topAbout__title{
  font-size: 2.2rem;
  text-align: center;
  line-height: line(31,22);

  @include media(md) {
    font-size: res(2.6rem);
  }

  @include media(lg) {
    font-size: 2.6rem;
  }
}

.topAbout__text{
  margin-top: 25px;
  font-size: 1.4rem;
  text-align: center;
  line-height: line(28,14);

  @include media(md) {
    margin-top: res(35px);
    font-size: res(1.6rem);
    line-height: line(32,16);
  }

  @include media(lg) {
    margin-top: 35px;
    font-size: 1.6rem;
  }
}

/* message
------------------------------------------------- */
.message{
  position: relative;
  padding-top: 75px;
  padding-bottom: 25px;

  @include media(sm) {
    padding-bottom: 40px;
  }

  @include media(md) {
    padding-top: res(150px);
    padding-bottom: res(85px);
  }

  @include media(lg) {
    padding-top: 150px;
    padding-bottom: 85px;
  }

  &::after{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 33.3333%;
    height: 655px;
    border-radius: 8px 0 0 8px;
    background-color: $bg-blue;

    @include media(sm) {
      width: 75%;
      height: res(300px);
    }

    @include media(lg) {
      height: 300px;
    }
  }
}

.message__wrap{
  margin: 0 auto;
  margin-top: 35px;
  width: 320px;
  max-width: 100%;

  @include media(sm) {
    display: flex;
    margin-top: res(50px);
    width: 560px;
  }

  @include media(md) {
    width: 100%;
  }

  @include media(lg) {
    margin-top: 50px;
  }
}

.message__img{
  margin: 0 auto;
  width: 222px;
  flex-shrink: 0;

  @include media(sm) {
    margin: 0;
    width: 210px;
  }

  @include media(md) {
    width: res(380px);
  }

  @include media(lg) {
    width: 380px;
  }

  & > img{
    width: 100%;
    height: auto;
  }
}

.message__txtBox{
  margin-top: 30px;

  @include media(sm) {
    margin-top: 20px;
    margin-left: 30px;
  }

  @include media(md) {
    margin-top: res(80px);
    margin-left: res(75px);
  }

  @include media(lg) {
    margin-top: 80px;
    margin-left: 75px;
  }
}

.message__title{
  font-size: 2.1rem;
  line-height: line(33,21);

  @include media(sm) {
    font-size: res(2.8rem);
    line-height: line(44,28);
  }

  @include media(lg) {
    font-size: 2.8rem;
  }
}

.message__text{
  margin-top: 20px;
  font-size: 1.4rem;
  line-height: line(28,14);

  @include media(sm) {
    margin-top: res(20px);
    font-size: res(1.6rem);
    line-height: line(32,16);
  }

  @include media(lg) {
    margin-top: 20px;
    font-size: 1.6rem;
  }
}

.message__co{
  margin-top: 25px;
  font-size: 1.2rem;

  @include media(sm) {
    margin-top: res(40px);
    font-size: res(1.4rem);
  }

  @include media(lg) {
    margin-top: 40px;
    font-size: 1.4rem;
  }
}

.message__name{
  margin-top: 10px;
  font-size: 1.5rem;

  @include media(sm) {
    margin-top: res(15px);
    font-size: res(2rem);
  }

  @include media(lg) {
    margin-top: 15px;
    font-size: 2rem;
  }
}

/* works
------------------------------------------------- */
.works{
  position: relative;
  overflow: hidden;
  margin-top: 80px;
  padding-top: 60px;

  @include media(sm) {
    padding-top: 80px;
  }

  @include media(md) {
    margin-top: res(200px);
    padding-top: res(140px);
  }

  @include media(lg) {
    margin-top: 200px;
    padding-top: 140px;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 92%;
    background-color: $bg-blue;

    @include media(sm) {
      width: 90%;
      height: 80%;
      min-width: 1050px;
      border-radius: 0 8px 8px 0;
    }

    @include media(md) {
      height: 77%;
    }
  }
}

.works__title{
  margin-top: 25px;
  font-size: 1.9rem;
  text-align: center;
  line-height: line(30,19);

  @include media(md) {
    margin-top: res(65px);
    font-size: res(2.8rem);
    line-height: line(44,28);
  }

  @include media(lg) {
    margin-top: 65px;
    font-size: 2.8rem;
  }
}

.works__text{
  margin-top: 25px;
  font-size: 1.3rem;
  text-align: center;
  line-height: line(25,13);

  @include media(md) {
    margin-top: res(30px);
    font-size: res(1.5rem);
    line-height: line(30,15);
  }

  @include media(lg) {
    margin-top: 30px;
    font-size: 1.5rem;
  }
}

.worksList{
  margin-top: 40px;
  padding: 0 10px;

  @include media(sm) {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin-left: -25px;
  }

  @include media(md) {
    margin-top: res(-5px);
    margin-left: res(-46px);
  }

  @include media(lg) {
    margin-top: -5px;
    margin-left: -46px;
  }
}

.worksList__item{
  margin-top: 35px;

  @include media(sm) {
    margin-left: 25px;
    width: calc(33.3333% - 25px);
  }

  @include media(md) {
    margin-top: res(70px);
    margin-left: res(46px);
    width: calc(33.3333% - #{res(46px)});
  }

  @include media(lg) {
    margin-top: 70px;
    margin-left: 46px;
    width: calc(33.3333% - 46px);
  }
}

.worksList__title{
  margin-top: 15px;
  font-size: 1.8rem;
  text-align: center;

  @include media(sm) {
    margin-top: 10px;
    font-size: 1.6rem;
  }

  @include media(md) {
    margin-top: res(25px);
    font-size: res(2.4rem);
  }

  @include media(lg) {
    margin-top: 25px;
    font-size: 2.4rem;
  }
}

.works__note{
  margin-top: 40px;
  font-size: 1.5rem;
  text-align: center;
  line-height: line(29,15);
  color: $color-blue;

  @include media(md) {
    margin-top: res(90px);
    font-size: res(2rem);
    line-height: line(30,20);
  }

  @include media(lg) {
    margin-top: 90px;
    font-size: 2rem;
  }
}

/* btnInsta
------------------------------------------------- */
.btnInsta{
  margin: 0 auto;
  margin-top: 15px;
  width: 222px;
  height: 45px;

  @include media(md) {
    margin-top: res(25px);
    width: res(296px);
    height: res(60px);
  }

  @include media(lg) {
    margin-top: 25px;
    width: 296px;
    height: 60px;
  }

  &>a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: $color-blue;
    border-radius: 25px;

    @include media(md) {
      border-radius: res(30px);
    }

    @include media(lg) {
      border-radius: 30px;
    }
  }
}

.btnInsta__icon{
  width: 127px;

  @include media(md) {
    width: res(170px);
  }

  @include media(lg) {
    width: 170px;
  }

  & .cls-1{
    fill: $color-wh;
  }
}

.btnInsta__text{
  margin-top: -2px;
  margin-left: 8px;
  font-size: 1.8rem;
  color: $color-wh;

  @include media(md) {
    margin-left: res(14px);
    font-size: res(2.4rem);
  }

  @include media(lg) {
    margin-left: 14px;
    font-size: 2.4rem;
  }
}

/* service
------------------------------------------------- */
.service{
  position: relative;
  padding-top: 100px;
  padding-bottom: 60px;

  @include media(md) {
    padding-top: res(210px);
    padding-bottom: res(120px);
  }

  @include media(lg) {
    padding-top: 210px;
    padding-bottom: 120px;
  }

  &::after{
    content: '';
    position: absolute;
    top: 4%;
    right: 0;
    z-index: -1;
    width: 33.3333%;
    height: 96%;
    border-radius: 8px 0 0 0;
    background-color: $bg-blue;

    @include media(md) {
      top: 18%;
      width: 25%;
      height: 82%;
    }
  }


  & .inner{
    padding: 0 30px;

    @include media(sm) {
      padding: 0 60px;

    }

    @include media(md) {
      padding: 0;
    }
  }
}

.service__text{
  margin-top: 30px;
  font-size: 1.5rem;
  line-height: line(29,15);

  @include media(md) {
    margin-top: res(45px);
    font-size: res(1.8rem);
    line-height: line(36,18);
  }

  @include media(lg) {
    margin-top: 45px;
    font-size: 1.8rem;
  }
}

.srvList{
  margin-top: 35px;

  @include media(sm) {
    display: flex;
    flex-wrap: wrap;
    margin-top: res(15px);
    margin-left: -35px;
  }

  @include media(md) {
    display: flex;
    flex-wrap: wrap;
    margin-top: res(15px);
    margin-left: res(-46px);
  }

  @include media(lg) {
    margin-top: 15px;
    margin-left: -46px;
  }
}

.srvList__item{
  margin-top: 35px;

  @include media(sm) {
    margin-top: 50px;
    margin-left: 35px;
    width: calc(50% - 35px);
  }

  @include media(md) {
    margin-top: res(35px);
    margin-left: res(46px);
    width: calc(33.3333% - #{res(46px)});
  }

  @include media(lg) {
    margin-top: 35px;
    margin-left: 46px;
    width: calc(33.3333% - 46px);
  }
}

.srvList__title{
  margin-top: 5px;
  font-size: 2.2rem;
  color: $color-blue;

  @include media(md) {
    margin-top: res(5px);
    font-size: res(2.2rem);
  }

  @include media(lg) {
    margin-top: 5px;
    font-size: 2.2rem;
  }
}

.srvList__text{
  margin-top: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: line(26,15);

  @include media(md) {
    margin-top: res(15px);
    font-size: res(1.5rem);
  }

  @include media(lg) {
    margin-top: 15px;
    font-size: 1.5rem;
  }

  &.--nallow{
    @include media(md) {
      letter-spacing: track(-60);
    }
  }
}

.srvPoint{
  margin-top: 55px;
  padding: 0 15px;

  @include media(md) {
    padding: 0;
    margin-top: res(70px);
  }

  @include media(lg) {
    margin-top: 70px;
  }
}

.srvPoint__text{
  position: relative;
  padding: 15px;
  font-size: 1.5rem;
  text-align: center;
  line-height: line(24,15);
  border-top: 2px solid $color-bk;
  border-bottom: 2px solid $color-bk;

  @include media(md) {
    margin: 0 auto;
    line-height: 1;
    width: 540px;
    padding: 8px 0 9px;
    font-size: res(1.8rem);
  }

  @include media(lg) {
    width: 664px;
    padding: 8px 0 10px;
    font-size: 1.8rem;
  }

  &::before{
    content: '';
    position: absolute;
    top: 50%;
    left: -5px;
    width: 2px;
    transform: translateY(-50%);
    height: calc(100% - 6px);
    background-color: $color-bk;

    @include media(md) {}

    @include media(lg) {
      left: -6px;
      width: 2px;
      height: calc(100% - 10px);
    }
  }

  &::after{
    content: '';
    position: absolute;
    top: 50%;
    right: -5px;
    width: 2px;
    transform: translateY(-50%);
    height: calc(100% - 6px);
    background-color: $color-bk;

    @include media(md) {}

    @include media(lg) {
      right: -6px;
      width: 2px;
      height: calc(100% - 10px);
    }
  }
}

/* company
------------------------------------------------- */
.company{
  padding-top: 60px;
  background-color: $bg-blue;
  overflow: hidden;

  @include media(md) {
    padding-top: res(200px);
  }

  @include media(lg) {
    padding-top: 200px;
  }
}

.company__wrap{
  @include media(md) {
    display: flex;
  }
}

.company__ttlArea{
  position: relative;
  padding: 17px;
  background-color: $color-bk;
  border-radius: 8px 0 0 0;

  @include media(md) {
    padding: 0px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: res(320px);
    border-radius: 8px 0 0 8px;
  }

  @include media(lg) {
    width: 320px;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(99.5%);
    width: 50%;
    height: 100%;
    background-color: $color-bk;

    @include media(md) {
      content: none;
    }
  }
}

.company__list{
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 50px 0 50px 28px;
  background-color: $color-wh;

  @include media(md) {
    padding: res(140px) 0 res(140px) res(120px);
  }

  @include media(lg) {
    padding: 140px 0 140px 120px;
  }

  &::after{
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(99.5%);
    z-index: -1;
    width: 50%;
    height: 100%;
    background-color: $color-wh;

    @include media(md) {
      width: 80vw;
    }

    @include media(lg) {}
  }
}

.company__item{
  display: flex;
  align-items: center;
  padding: 15px 0 17px;
  border-top: 1px solid $line-gray;

  @include media(md) {
    padding: res(20px) 0 res(22px);
  }

  @include media(lg) {
    padding: 20px 0 22px;
  }

  &:last-child{
    border-bottom: 1px solid $line-gray;
  }
}

.company__title{
  font-size: 1.3rem;
  line-height: line(21,13);
  width: 50px;

  @include media(md) {
    font-size: res(1.8rem);
    line-height: 1;
    width: res(60px);
  }

  @include media(lg) {
    font-size: 1.8rem;
    width: 60px;
  }
}

.company__text{
  margin-left: 10px;
  font-size: 1.3rem;
  line-height: line(21,13);

  @include media(md) {
    margin-left: res(70px);
    font-size: res(1.8rem);
    line-height: 1;
  }

  @include media(lg) {
    margin-left: 70px;
    font-size: 1.8rem;
  }

  & > a{
    text-decoration: underline;
  }
}

/* topRct
------------------------------------------------- */
.topRct{
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: $bg-blue;

  @include media(md) {
    padding-top: res(200px);
    padding-bottom: res(200px);
  }

  @include media(lg) {
    padding-top: 200px;
    padding-bottom: 200px;
  }
}

.topRct__banner > a{
  position: relative;
  display: block;
  width: 100%;
  padding: 50px 0 35px;
  background-image: url(../images/pages/top/top_recruit_sp@2x.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 8px;

  @include media(md) {
    background-image: url(../images/pages/top/top_recruit_pc@2x.png);
    padding: res(77px) 0 res(65px);
  }

  @include media(lg) {
    padding: 77px 0 65px;
  }

  &::after{
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 9.5px;
    height: 17px;
    background-image: url(../images/common/icon/button_arrow.svg);
    background-repeat: no-repeat;
    background-size: contain;

    @include media(md) {
      right: res(40px);
      width: res(14px);
      height: res(25px);
    }

    @include media(lg) {
      right: 40px;
      width: 14px;
      height: 25px;
    }
  }
}

.topRct__title{
  display: flex;
  flex-direction: column;
  align-items: center;

  .en{
    height: 24px;

    @include media(md) {
      height: res(38px);
    }

    @include media(lg) {
      height: 38px;
    }

    &>img{
      height: 100%;
      width: auto;
    }
  }

  .jp {
    position: relative;
    margin-top: 20px;
    padding:  6px 13px 8px;
    font-size: 1.4rem;
    font-weight: 700;
    color: $color-wh;
    border-top: 2px solid $color-wh;
    border-bottom: 2px solid $color-wh;

    @include media(md) {
      margin-top: res(28px);
      padding:  res(10px) res(22px) res(12px);
      font-size: res(1.8rem);
    }

    @include media(lg) {
      margin-top: 28px;
      padding:  10px 22px 12px;
      font-size: 1.8rem;
    }

    &::before{
      content: '';
      position: absolute;
      top: 50%;
      left: -5px;
      width: 2px;
      transform: translateY(-50%);
      height: calc(100% - 8px);
      background-color: $color-wh;

      @include media(md) {
        left: res(-7px);
        width: 2px;
        height: calc(100% - #{res(10px)});
      }

      @include media(lg) {
        left: -7px;
        height: calc(100% - 10px);
      }
    }

    &::after{
      content: '';
      position: absolute;
      top: 50%;
      right: -5px;
      width: 2px;
      transform: translateY(-50%);
      height: calc(100% - 8px);
      background-color: $color-wh;

      @include media(md) {
        right: res(-7px);
        width: 2px;
        height: calc(100% - 10px);
      }

      @include media(lg) {
        right: -7px;
        height: calc(100% - 10px);
      }
    }
  }
}

.topRct__text{
  margin-top: 15px;
  font-size: 1.3rem;
  text-align: center;
  font-weight: 500;
  line-height: line(24,13);
  color: $color-wh;

  @include media(md) {
    margin-top: res(25px);
    font-size: res(1.8rem);
  }

  @include media(lg) {
    margin-top: 25px;
    font-size: 1.8rem;
  }
}

/* feed
------------------------------------------------- */
.feed{
  padding-top: 65px;
  padding-bottom: 60px;

  @include media(md) {
    padding-top: res(120px);
    padding-bottom: res(140px);
  }

  @include media(lg) {
    padding-top: 120px;
    padding-bottom: 140px;
  }
}

.feed__title{
  margin: 0 auto;
  width: 170px;

  @include media(md) {
    width: res(170px);
  }

  @include media(lg) {
    width: 170px;
  }

  & .cls-1{
    fill: $color-bk;
  }
}

.feed__text{
  margin-top: 25px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  line-height: line(26,15);

  @include media(md) {
    margin-top: res(45px);
    font-size: res(1.7rem);
  }

  @include media(lg) {
    margin-top: 45px;
    font-size: 1.7rem;
  }
}

.feed__block{
  margin-top: 16px;

  @include media(md) {
    margin-top: res(34px);
  }

  @include media(lg) {
    margin-top: 34px;
  }
}

/* topContact
------------------------------------------------- */
.topContact{
  padding-top: 60px;
  padding-bottom: 55px;
  background-color: $bg-gray;

  @include media(md) {
    padding-top: res(110px);
    padding-bottom: res(100px);
  }

  @include media(lg) {
    padding-top: 110px;
    padding-bottom: 100px;
  }
}

.topContact__text{
  margin-top: 30px;
  font-size: 1.2rem;
  text-align: center;
  line-height: line(25,12);

  @include media(md) {
    margin-top: res(45px);
    font-size: res(1.6rem);
    line-height: line(34,16);
  }

  @include media(lg) {
    margin-top: 45px;
    font-size: 1.6rem;
  }

  & .blue{
    color: $color-blue;
  }
}

.topContact__button{
  margin-top: 30px;

  @include media(md) {
    margin-top: res(50px);
  }

  @include media(lg) {
    margin-top: 50px;
  }
}

.telBox{
  margin-top: 30px;

  @include media(md) {
    margin-top: res(45px);
  }

  @include media(lg) {
    margin-top: 45px;
  }
}

.telBox__text{
  margin-top: 10px;
  font-size: 1.3rem;
  line-height: line(24,13);
  text-align: center;

  @include media(md) {
    margin-top: res(13px);
    font-size: res(1.6rem);
    line-height: line(24,13);
  }

  @include media(lg) {
    margin-top: 13px;
    font-size: 1.6rem;
  }
}

.telBox__tel{
  margin-top: 5px;
  font-size: 3rem;
  color: $color-blue;
  text-align: center;

  @include media(md) {
    margin-top: 3px;
    font-size: res(4rem);
  }

  @include media(lg) {
    font-size: 4rem;
  }
}

/* map
------------------------------------------------- */
.map{
  width: 100%;
  height: 330px;

  @include media(md) {
    height: res(440px);
  }

  @include media(lg) {
    height: 440px;
  }
}



/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
}
