//@keyframesから定義するanimationの設定
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scrollCircle {
  0% {
    -webkit-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
  }

  85% {
    -webkit-transform: translate(-50%, 110%);
    transform: translate(-50%, 110%);
  }

  100% {
    -webkit-transform: translate(-50%, -60%);
    transform: translate(-50%, -60%);
  }
}

/* パララックス用
----------------------------------------------- */
.js-parent {
  & > .sec__title,
  & > .topNews__inner,
  .topMenu__list > li {
    transform: translateY(50px);
    opacity: 0;
    transition:
      transform 1.2s,
      opacity 1.2s;
  }

  & > .topNews__inner,
  .topMenu__list > li {
    transition-delay: 0.4s;
  }

  .topMenu__list > li {
    &:nth-child(2) {
      transition-delay: 0.65s;
    }

    &:nth-child(3) {
      transition-delay: 0.9s;
    }

    &:nth-child(4) {
      transition-delay: 1.15s;
    }

    &:nth-child(5) {
      transition-delay: 1.4s;
    }

    &:nth-child(6) {
      transition-delay: 1.65s;
    }
  }

  &.isFade {
    & > .sec__title,
    & > .topNews__inner,
    .topMenu__list > li {
      transform: translate(0);
      opacity: 1;
    }
  }
}

.js-fadeIn {
  transform: translateY(50px);
  opacity: 0;
  transition:
    transform 1.5s,
    opacity 1.5s;

  &.isFade {
    transform: translate(0);
    opacity: 1;
  }
}
